/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getMemberType(data) {

    return api.get(`/member_type/list?search=${data.search}&size=${data.size}&page=${data.page}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },
  getAll() {
    return api.get(`/member_type/get_all`)
  },

  showMemberType(data){
    return api.get(`member_type/show/${data.id}`)
  },

  memberTypeStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`member_type/store`, params, {})
  },

  memberTypeUpdate(data) {
    let getFormData = data
    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`member_type/update`, params, {})
  },

  memberTypeDestroy(data) {
    let getFormData = data

    let params = new FormData();
    // for (var key in getFormData) {
    //   if (getFormData.hasOwnProperty(key)) {
    //     params.append(key, getFormData[key]);
    //   }
    // }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    // console.log(data.member_type_id,555)
    return api.delete(`member_type/delete/${data.member_type_id}`, params, {})
  },

}
