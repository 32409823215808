<template>
  <v-data-table
    :headers="headers"
    :items="memberTypes"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              สร้างกลุ่มลูกค้าใหม่
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.member_type_name"
                      label="ชื่อกลุ่มลูกค้า"
                    ></v-text-field>
                    <small
                      class="validate-err"
                      v-for="(err,index) in v$.editedItem.member_type_name.$silentErrors"
                      v-if="v$.editedItem.member_type_name.$error">{{ index > 0 ? ', ' : '' }}
                      {{ err.$message }}</small>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.member_type_comment"
                      label="คำอธิบาย"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance_memberType from '@/services/memberType'
import Swal from 'sweetalert2'
import {useVuelidate} from '@vuelidate/core'
import {required, email} from '@vuelidate/validators'

export default {
  components: {
    Swal
  },
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,

    },
    total: 0,
    memberTypes: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'ชื่อกลุ่มลูกค้า', value: 'member_type_name'},
      {text: 'คำอธิบาย', value: 'member_type_comment'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      member_type_name: '',
      member_type_comment: ''
    },
    defaultItem: {
      member_type_name: '',
      member_type_comment: ''
    },
  }),
  validations() {
    return {
      editedItem: {
        member_type_name: {
          required,
        }
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มลูกค้า' : 'แก้ไข กลุ่มลูกค้า'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getMemberType()
      },
    },
    search: {
      handler() {
        this.getMemberType()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getMemberType()
  },

  methods: {
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.memberTypes.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.memberTypes.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {

      instance_memberType.memberTypeDestroy(this.editedItem)
        .then(res => {

          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
          this.getMemberType()
          this.closeDelete()
        }).catch(err => {

      });
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.v$.$reset()
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true;

      if (this.editedIndex > -1) {
        instance_memberType.memberTypeUpdate(this.editedItem)
          .then(res => {
            // Object.assign(this.memberTypes[this.editedIndex], this.editedItem)

            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getMemberType()
            this.close()
          }).catch(err => {

          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })

          this.$store.state.isLoading = false
        });

      } else {
        instance_memberType.memberTypeStore(this.editedItem)
          .then(res => {
            // this.memberTypes.splice(0,0,this.editedItem)
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            },150)
            this.getMemberType()
            this.close()

            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false;

        });
      }
    },
    getMemberType() {
      this.$store.state.isLoading = true;
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }
      instance_memberType.getMemberType(data)
        .then(res => {
          if (res.status === 200) {
            this.memberTypes = res.data.data.data
            this.total = res.data.data.total
            this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
            this.serverOptions.page = res.data.data.current_page
            this.$store.state.isLoading = false;
          }
        })
        .catch(error => {
          console.log(error)
        });
    }
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
